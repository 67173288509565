var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reward"
  }, [!_vm.hasAward ? _c('div', {
    staticClass: "box y3"
  }, [_c('div', {
    staticClass: "x3 aic"
  }, [_c('p', {
    staticClass: "blue bold pointer",
    on: {
      "click": _vm.showAddDialog
    }
  }, [_vm._v(" +创建带看奖励 ")])]), _c('p', {
    staticClass: "color9"
  }, [_vm._v(" 创建带看奖励后，项目报备页面或项目详情页，将展示带看奖励活动内容，活动结束后请及时关闭带看奖励 ")])]) : _c('div', {
    staticClass: "box y3",
    on: {
      "click": _vm.showEditDialog
    }
  }, [_c('div', {
    staticClass: "x3 aic"
  }, [_c('p', {
    staticClass: "bold",
    class: {
      blue: !_vm.hasAward
    }
  }, [_vm._v(" " + _vm._s(_vm.applyAward.title) + " ")]), _c('div', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('el-switch', {
    on: {
      "change": _vm.openCloseEstateAward
    },
    model: {
      value: _vm.applyAward.awardStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.applyAward, "awardStatus", $$v);
      },
      expression: "applyAward.awardStatus"
    }
  })], 1)]), _c('p', {
    staticClass: "color9 ellipsis"
  }, [_vm._v(" " + _vm._s(_vm.applyAward.ruleDescription) + " ")])]), _c('window', {
    model: {
      value: _vm.showWindow,
      callback: function callback($$v) {
        _vm.showWindow = $$v;
      },
      expression: "showWindow"
    }
  }, [_c('div', {
    staticClass: "create-window"
  }, [_c('div', {
    staticClass: "x3 aic",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c('div', {
    staticClass: "dao-title"
  }, [_vm._v(" " + _vm._s(_vm.formData.id ? "编辑" : "创建") + "带看奖励 ")])]), _c('el-form', {
    ref: "form",
    attrs: {
      "label-position": "top",
      "label-width": "80px",
      "model": _vm.formData
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "标题",
      "prop": "title",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "规则描述",
      "prop": "ruleDescription",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 8,
      "maxlength": "300",
      "show-word-limit": "",
      "placeholder": "请输入带看奖励活动规则，支持换行"
    },
    model: {
      value: _vm.formData.ruleDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ruleDescription", $$v);
      },
      expression: "formData.ruleDescription"
    }
  })], 1)], 1), _c('div', {
    staticClass: "x2"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": function click($event) {
        _vm.showWindow = false;
      }
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "size": "small",
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" 保 存 ")])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }