<template>
  <div class="reward">
    <div class="box y3" v-if="!hasAward">
      <div class="x3 aic">
        <p class="blue bold pointer" @click="showAddDialog">
          +创建带看奖励
        </p>
      </div>
      <p class="color9">
        创建带看奖励后，项目报备页面或项目详情页，将展示带看奖励活动内容，活动结束后请及时关闭带看奖励
      </p>
    </div>
    <div class="box y3" v-else @click="showEditDialog">
      <div class="x3 aic">
        <p class="bold" :class="{ blue: !hasAward }">
          {{ applyAward.title }}
        </p>
        <div @click.stop>
          <el-switch
            v-model="applyAward.awardStatus"
            @change="openCloseEstateAward"
          ></el-switch>
        </div>
      </div>
      <p class="color9 ellipsis">
        {{ applyAward.ruleDescription }}
      </p>
    </div>
    <window v-model="showWindow">
      <div class="create-window">
        <div class="x3 aic" style="margin-bottom: 24px;">
          <div class="dao-title">
            {{ formData.id ? "编辑" : "创建" }}带看奖励
          </div>
        </div>
        <el-form
          label-position="top"
          label-width="80px"
          :model="formData"
          ref="form"
        >
          <el-form-item label="标题" prop="title" :rules="[rules.required()]">
            <el-input type="text" placeholder="请输入" v-model="formData.title">
            </el-input>
          </el-form-item>
          <el-form-item
            label="规则描述"
            prop="ruleDescription"
            :rules="[rules.required()]"
          >
            <el-input
              type="textarea"
              :rows="8"
              maxlength="300"
              show-word-limit
              placeholder="请输入带看奖励活动规则，支持换行"
              v-model="formData.ruleDescription"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="x2">
          <el-button size="small" @click="showWindow = false">取 消</el-button>
          <el-button size="small" type="primary" @click="submit">
            保 存
          </el-button>
        </div>
      </div>
    </window>
  </div>
</template>

<script>
import {
  getEstateApplyAward,
  createEstateAward,
  editEstateAward,
  openCloseEstateAward
} from "@/api/distribution";
import window from "@/components/window";
import rules from "@/utils/rules";

const formData = {
  title: "",
  ruleDescription: ""
};
export default {
  components: {
    window
  },
  data() {
    return {
      rules,
      hasAward: false,
      applyAward: {},
      status: true,
      showWindow: false,
      formData: JSON.parse(JSON.stringify(formData))
    };
  },
  computed: {
    estateInfo() {
      return this.$store.state.estateInfo;
    }
  },
  created() {
    this.getEstateApplyAward();
  },
  methods: {
    async getEstateApplyAward() {
      let query = {
        estateId: this.estateInfo.estateId
      };
      const data = await getEstateApplyAward(query);
      if (data) {
        this.applyAward = data;
        this.hasAward = !!data.id;
      }
    },
    async openCloseEstateAward() {
      let query = {
        id: this.applyAward.id,
        isEnabled: this.applyAward.awardStatus
      };
      const data = await openCloseEstateAward(query);
      if (data) {
        this.getEstateApplyAward();
      }
    },
    showAddDialog() {
      //点击创建
      this.formData = this.$deepClone(formData);
      this.formData.estateId = this.estateInfo.estateId;
      this.showWindow = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
    async showEditDialog() {
      //点击编辑
      this.formData = this.$deepClone(this.applyAward);
      this.formData.estateId = this.estateInfo.estateId;
      this.showWindow = true;
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return;
        }
        const fn = this.formData.id ? editEstateAward : createEstateAward;
        const res = await fn(this.formData);
        if (res) {
          this.$showSuccess("提交成功");
          this.showWindow = false;
          this.formData = this.$deepClone(formData);
          this.$refs.form.resetFields();
          this.getEstateApplyAward();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.reward {
  padding: 24px;
  background: #ffffff;
  min-height: 100%;
  .box {
    width: 765px;
    margin: 0 auto;
    border: 1px solid #eeeeee;
    height: 90px;
    border-radius: 5px;
    padding: 20px 40px;
    &:hover {
      background: #f2f2f2;
    }
  }
  .create-window {
    width: 765px;
    margin: 0 auto;
  }
}
</style>
