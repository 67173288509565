var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ifValue ? _c('transition', {
    attrs: {
      "name": "el-fade-in-linear",
      "value": _vm.value
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "window"
  }, [_c('i', {
    staticClass: "el-icon-close close",
    on: {
      "click": function click($event) {
        _vm.ifValue = false;
      }
    }
  }), _vm._t("default")], 2)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }