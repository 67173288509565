<template>
  <transition name="el-fade-in-linear" :value="value" v-if="ifValue">
    <div class="wrapper">
      <div class="window">
        <i class="el-icon-close close" @click="ifValue = false"></i>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ifValue: false
    };
  },
  watch: {
    value(bool) {
      this.ifValue = bool;
    },
    ifValue(val) {
      /*使用了v-model的组件会自动监听 input 事件,
       * 并把这个input事件所携带的值 传递给v-model所绑定的属性,
       * 这样组件内部的值就给到了父组件了
       */
      this.$emit("input", val); //传值给父组件, 让父组件监听到这个变化
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: calc(100vw - 220px);
  height: calc(100vh - 60px);
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  background: #fbfbfb;
  padding: 20px;
  .window {
    padding: 24px;
    width: 100%;
    background: #ffffff;
    min-height: 100%;
    position: relative;
    padding-top: 60px;
    padding-bottom: 40px;
    overflow: auto;
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 28px;
      color: #999;
      cursor: pointer;
    }
  }
}
</style>
